
import ProductList from "@/components/ProductList.vue";
import { store } from "@/store";
import { defineComponent, computed, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useAlert } from "balm-ui";

export default defineComponent({
  components: {
    ProductList,
  },
  setup() {
    const route = useRoute();
    const $alert = useAlert();
    if (
      !route.query ||
      !route.query.register ||
      !route.query.login ||
      !route.query.sign
    ) {
      $alert("Please check if the URL is correct, refresh and try again");
      return;
    }
    const data = {
      login: route.query.login,
      register: Number(route.query.register),
      sign: route.query.sign,
    };
    store
      .dispatch("user/login", data)
      .then((res) => {
        store.dispatch("user/get_license", {});
      })
      .catch((e) => {
      });
    return {};
  },
});
