
import { defineComponent, computed, reactive } from "vue";
import { store, useStore } from "@/store";
import { withdraw } from "@/api/user";
import { useAlert } from "balm-ui";
import { useI18n } from "vue-i18n";
export default defineComponent({
  setup() {
    const $alert = useAlert();
    const { t } = useI18n();
    const state = reactive({
      openDialog: false,
      nftAddress: "",
      isLoading: false,
    });
    const handleWithdraw = (Mint = "1"): void => {
      if (String(Mint) === "1") {
        return;
      }
      // 输入钱包地址
      state.isLoading = true;
      state.openDialog = true;
    };
    // 确认输入收货地址
    const onConfirmAddAddress = (result: any) => {
      if (result) {
        if (state.nftAddress.length === 0) {
          $alert(t("goods.wrong_address_tip"));
          return;
        }
        go_to_pay();
      } else {
        state.isLoading = false;
      }
      state.openDialog = false;
    };

    const go_to_pay = () => {
      withdraw({
        address: state.nftAddress,
      })
        .then(() => {
          $alert(t("message.successful"));
          state.isLoading = false;
          state.nftAddress = "";
          store.dispatch("user/get_license", {});
        })
        .catch((e) => {
          state.isLoading = false;
          state.nftAddress = "";
          store.dispatch("user/get_license", {});
        });
    };

    return {
      isShowProduct: computed(() => store.state.user.isShowProduct),
      product: computed(() => store.state.user.product),
      state,
      handleWithdraw: handleWithdraw,
      go_to_pay: go_to_pay,
      onConfirmAddAddress: onConfirmAddAddress,
    };
  },
});
